import clsx from 'clsx'

interface ContainerProps {
  paddingX?: string
}

export function Container({
  className,
  paddingX = 'px-4 sm:px-6 lg:px-32',
  ...props
}: React.ComponentPropsWithoutRef<'section'> & ContainerProps) {
  return (
    <section
      className={clsx('mx-auto max-w-screen-2xl', paddingX, className)}
      {...props}
    />
  )
}
