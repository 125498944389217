import Image, { ImageProps } from 'next/image'

import NoraLogo from '@/_assets/images/nora_round_logo.png'

interface LogoProps extends Omit<ImageProps, 'src' | 'alt'> {}

export function Logo(props: LogoProps) {
  const { width, height, ...rest } = props
  return (
    <Image
      src={NoraLogo}
      alt='Nora Language Tutor Logo'
      width={width ?? 72}
      height={height ?? 72}
      className='min-w-[72px] max-w-full'
      {...rest}
    />
  )
}
