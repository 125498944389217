'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { MAILCHIMP_TAGS } from '@repo/utilities'

import { subscribeToMailchimp } from '@/_actions/mailchimp'
import { generateToastCookie } from '@/_actions/utils'
import { getTypedFormData, TypedFormDataValue } from '@/_utils/typedFormData'

import { Button } from '../Button'
import { Container } from '../Container'
import { FormControl } from '../FormControl'
import { InputWrapper } from '../InputWrapper'
import { Toast } from '../Toast'

const SignUpEmailFormSchema = z.object({
  email: z.string().email('Invalid email').min(1, 'Email is required'),
})

type Inputs = z.infer<typeof SignUpEmailFormSchema>
export type SignUpEmailFormData = {
  [K in keyof Inputs]: TypedFormDataValue
}

export function StayInTouch() {
  const defaultValues: Inputs = {
    email: '',
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    resolver: zodResolver(SignUpEmailFormSchema),
    defaultValues,
  })

  const onSubmit = async (data: Inputs) => {
    if (isSubmitting) return
    const formData = getTypedFormData<SignUpEmailFormData>()
    Object.keys(data).forEach((key) => {
      const typedKey = key as keyof Inputs
      const value = data[typedKey]
      if (value !== null) formData.append(typedKey, value)
    })
    const ok: boolean = await subscribeToMailchimp(formData, [
      MAILCHIMP_TAGS.NEWSLETTER,
    ])
    if (ok) {
      generateToastCookie({
        message: 'Thank you for subscribing!',
        type: 'success',
      })
      reset()
    } else {
      generateToastCookie({
        message:
          'Something went wrong. Please try again, or contact support@hellonora.ai',
        type: 'error',
      })
    }
  }

  return (
    <div className='relative overflow-hidden py-14 bg-[#1c1c47]'>
      <Container id='stay-in-touch' aria-label='Stay in touch with us'>
        <div className='flex flex-col w-full text-center items-center gap-4'>
          <h3 className='font-bold max-w-4xl text-h3 text-white'>
            Stay in touch with the Nora team
          </h3>
          <p className='text-lg tracking-tight text-white/80'>
            We send nice email updates once in a while :)
          </p>
          <div className='flex max-w-[670px] w-full mt-3'>
            <form
              action='#'
              className='flex flex-1 gap-1 justify-center'
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormControl
                name='email'
                labelSize='text'
                labelFontWeight='font-semibold'
                error={errors.email}
                className='relative w-full'
              >
                <InputWrapper
                  id='email'
                  placeholder='you@example.com'
                  register={register('email')}
                  className='py-[12px]'
                />
                <div className='absolute right-[3px] top-[3px]'>
                  <Button type='submit' color='red' disabled={isSubmitting}>
                    Stay in touch
                  </Button>
                </div>
              </FormControl>
            </form>
          </div>
        </div>
        <Toast />
      </Container>
    </div>
  )
}
