import clsx from 'clsx'
import Link from 'next/link'

export function NavLink({
  href,
  children,
  textColor = 'text-regalBlue/80',
}: {
  href: string
  children: React.ReactNode
} & { textColor?: string }) {
  return (
    <Link
      href={href}
      className={clsx(
        'inline-block rounded-3xl px-2 py-1 text-base hover:bg-slate-100 hover:text-regalBlue',
        textColor
      )}
    >
      {children}
    </Link>
  )
}
