import clsx from 'clsx'
import { UseFormRegisterReturn } from 'react-hook-form'

interface InputWrapperProps {
  id: string
  type?: string
  placeholder?: string
  register: UseFormRegisterReturn
  className?: string
}

export const InputWrapper = ({
  id,
  type = 'text',
  placeholder,
  register,
  className,
}: InputWrapperProps) => (
  <input
    {...register}
    id={id}
    type={type}
    className={clsx('rounded-3xl border bg-white px-4 py-2', className ?? '')}
    placeholder={placeholder}
  />
)
