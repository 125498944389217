'use client'

import { Popover, Transition } from '@headlessui/react'
import clsx from 'clsx'
import Link from 'next/link'
import { Fragment } from 'react'
import { reverseUrl } from '@repo/utilities'

import { Container } from '@/_components/Container'
import { NavLink } from '@/_components/NavLink'
import { NORA_LOGIN_URL, NORA_SIGN_UP_URL } from '@/_utils/urls'

import { Button } from '../Button'
import { Logo } from '../Logo'

function MobileNavLink({
  href,
  children,
  className,
  ...props
}: {
  href: string
  children: React.ReactNode
  className?: string
}) {
  return (
    <Popover.Button
      {...props}
      as={Link}
      href={href}
      className={`block w-full p-2 ${className ?? ''}`}
    >
      {children}
    </Popover.Button>
  )
}

function MobileNavIcon({ open }: { open: boolean }) {
  return (
    <svg
      aria-hidden='true'
      className='h-3.5 w-3.5 overflow-visible stroke-regalBlue'
      fill='none'
      strokeWidth={2}
      strokeLinecap='round'
    >
      <path
        d='M0 1H14M0 7H14M0 13H14'
        className={clsx(
          'origin-center transition',
          open && 'scale-90 opacity-0'
        )}
      />
      <path
        d='M2 2L12 12M12 2L2 12'
        className={clsx(
          'origin-center transition',
          !open && 'scale-90 opacity-0'
        )}
      />
    </svg>
  )
}

function MobileNavigation() {
  return (
    <Popover>
      <Popover.Button
        className='ui-not-focus-visible:outline-none relative z-10 flex h-8 w-8 items-center justify-center'
        aria-label='Toggle Navigation'
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter='duration-150 ease-out'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='duration-150 ease-in'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Popover.Overlay className='fixed inset-0 bg-slate-300/50' />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter='duration-150 ease-out'
          enterFrom='opacity-0 scale-95'
          enterTo='opacity-100 scale-100'
          leave='duration-100 ease-in'
          leaveFrom='opacity-100 scale-100'
          leaveTo='opacity-0 scale-95'
        >
          <Popover.Panel
            as='div'
            className='absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-regalBlue shadow-xl ring-1 ring-slate-900/5'
          >
            <MobileNavLink href='#testimonials'>Reviews</MobileNavLink>
            <MobileNavLink href='#pricing'>Pricing</MobileNavLink>
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  )
}

export function Header() {
  const homeImageHref = reverseUrl('index')

  return (
    <header className='py-2'>
      <Container>
        <nav className='relative z-50 flex justify-between'>
          <div className='flex items-center md:gap-x-8'>
            <Link href={homeImageHref} aria-label='Home'>
              <Logo />
            </Link>
            <div className='hidden text-nowrap md:flex md:gap-x-4 lg:gap-x-6'>
              <NavLink href='#testimonials'>Reviews</NavLink>
              <NavLink href='#pricing'>Pricing</NavLink>
            </div>
          </div>
          <div className='flex items-center gap-x-5 md:gap-x-8'>
            <div className='flex flex-1 flex-col lg:w-[170px] self-center text-nowrap'>
              <Button href={NORA_LOGIN_URL} outline>
                <span>Login</span>
              </Button>
            </div>
            <div className='flex flex-1 flex-col lg:w-[170px] self-center text-nowrap'>
              <Button
                href={NORA_SIGN_UP_URL}
                data-app-origin='marketing'
                className='start-signup-button'
                color='blue'
              >
                <span>Sign Up</span>
              </Button>
            </div>
            <div className='-mr-1 md:hidden'>
              <MobileNavigation />
            </div>
          </div>
        </nav>
      </Container>
    </header>
  )
}
